import React from "react";
import Seo from "../components/shared/seo";
import { Link } from "gatsby";
import Logo from "../images/sharedImages/pdf_logo.svg";
import styled from "styled-components";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/shared/layout";

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  overflow: hidden;

  .logo-icon {
    margin-bottom: 24px;
    fill: black;
  }
`;

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <Style>
        <Logo className="logo-icon" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
        <p>
          Go back to <Link to="/">Homepage</Link>
        </p>
      </Style>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
